import {translate} from 'sulu-admin-bundle/utils';
import {AbstractFormToolbarAction} from 'sulu-admin-bundle/views';
import {Requester} from 'sulu-admin-bundle/services';

export default class NewsletterExportAction extends AbstractFormToolbarAction {
  getToolbarItemConfig() {
    const formData = this.resourceFormStore.data;

    return {
      type: 'button',
      label: translate('app.newsletter_export'),
      icon: 'su-download',
      onClick: this.handleClick,
    };
  }

  handleClick = (id) => {
    Requester.post('/admin/api/newsletter/' + this.form.collaborationStore.id + '/export')
    .then((res) => {
      alert(res.message);
      window.location.reload();
    })
    .catch(() => {
      alert('Une erreur est survenue');
    })
  };
}