import {translate} from 'sulu-admin-bundle/utils';
import {AbstractFormToolbarAction} from 'sulu-admin-bundle/views';
import {Requester} from 'sulu-admin-bundle/services';

export default class NewsletterSendAction extends AbstractFormToolbarAction {
  getToolbarItemConfig() {
    const formData = this.resourceFormStore.data;

    return {
      type: 'button',
      label: translate('app.newsletter_send'),
      icon: 'su-envelope',
      onClick: this.handleClick,
    };
  }

  handleClick = (id) => {
    Requester.post('/admin/api/newsletters/' + this.form.collaborationStore.id + '/send')
    .then((res) => {
      alert('Mail envoyé à ' + res.destinataire);
    })
    .catch(() => {
      alert('Une erreur est survenue');
    })
  };
}