import {translate} from 'sulu-admin-bundle/utils';
import {AbstractFormToolbarAction} from 'sulu-admin-bundle/views';
import {Requester} from 'sulu-admin-bundle/services';

export default class NewsletterSendAction extends AbstractFormToolbarAction {
  getToolbarItemConfig() {
    const formData = this.resourceFormStore.data;

    return {
      type: 'button',
      label: translate('app.newsletter_import'),
      icon: 'su-upload',
      onClick: this.handleClick,
    };
  }

  handleClick = (id) => {
    Requester.post('/admin/api/informations/' + this.form.collaborationStore.id + '/import')
    .then((res) => {
      let text = "L'importation est terminer,Pour l'afficher sur sélectionner besoin d'actualiser la page ";
      if (confirm(text) == true) {
        window.location.reload();
      }
    })
    .catch(() => {
      alert('Une erreur est survenue');
    })
  };
}